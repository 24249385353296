import styles from './Home.module.scss';
import React, { useState, useRef, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

function Home() {
  const [timer, setTimer] = useState({
    minutes: 0,
    seconds: 15,
    time: 0,
    running: false,
    finished: false,
    background: '#fff',
    playAudio: true
  });
  const audio = useRef(null);

  const updateTime = (e) => {
    setTimer({...timer, [e.target.name]: e.target.value});
  }

  const start = () => {
    const time = (parseInt(timer?.minutes || 0) * 60) + parseInt(timer?.seconds || 0);

    if (!time) {
      alert('Please specify a time limit');
      return;
    }

    setTimer({...timer, time, running: true});
  }

  const stop = () => {
    setTimer({
      ...timer,
      time: 0,
      running: false,
      finished: false,
      background: '#fff'
    });
  }

  const audioFile = 'alarm-clock.wav';

  useEffect(() => {
    if (timer.finished && timer.playAudio) {
      audio.current.play();
    }
  }, [timer.finished, timer.playAudio, audio]);

  return (
    <>
      {
        timer.running
          ? <button className={styles.stop} style={{backgroundColor: timer.background}} onClick={stop}>
              <audio src={audioFile} preload="auto" ref={audio} />
              <div className={styles.counter}>
                {
                  timer.finished
                    ? <span className={styles.finished}>STOP</span>
                    : <CountdownCircleTimer
                      isPlaying={timer.time}
                      duration={timer.time}
                      strokeWidth={20}
                      size={300}
                      trailColor="#fff"
                      colors={[
                        ['#3a966d', 0.33],
                        ['#a30000', 0.33],
                      ]}
                      onComplete={() => {
                        setTimer({...timer, background: '#a30000', finished: true});
                      }}
                    >
                      {({remainingTime}) => (<span className={styles.time}>{remainingTime}</span>)}
                    </CountdownCircleTimer>
                }
              </div>
            </button>
          : <>
            <header className={styles.header}>
              <h1>t</h1>
              <div className={styles.time}>
                <input type="number" name="minutes" placeholder="mins" value={timer.minutes} onChange={updateTime}/>
                <span className={styles.colon}>:</span>
                <input type="number" name="seconds" placeholder="secs" value={timer.seconds} onChange={updateTime}/>
              </div>
            </header>
            <button className={styles.start} onClick={start}>GO</button>
          </>
      }
    </>
  );
}

export default Home;
